import axios from "axios";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useAuth = () => {
  //Get User
  const [auth, setAuth] = useState(null);

  //get section
  const { pathname } = useLocation();
  const path = pathname.split('/');
  const bot = path[1];

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL + '/session?bot=' + bot, {
      withCredentials: true
    }).then(({data}) => {
      setAuth(data);
    });
  }, []);

  return auth;
}

export default useAuth;