import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Item = (props) => {

    return (
        <Col lg="2" md="3" sm="6">
            <Link to={"../" + props.link + "/" + props.id} className="no-link">
                <div className="text-center">
                    <img className="item-image shadow" src={props.src} alt={props.name} />
                    {props.name}
                </div>
            </Link>
        </Col>
    );
}

export default Item;