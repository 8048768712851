import { useState, useEffect } from 'react';

const useFetch = (url) => {
    const [data, setData] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    async function fetchUrl(path) {
        fetch(path, {
            credentials: 'include'
        }).then(res => {
            if (!res.ok) throw Error("Couldn't fetch the data.");    
            return res.json();
        }).then(data => {
            setData(data);
            setIsPending(false);
            setError(null);
        }).catch(err => {
            setIsPending(false);
            setError(err.message);
        });
    }

    useEffect(() => {
        fetchUrl(url);
    }, []);

    return { data, isPending, error, fetchUrl }
}

export default useFetch;