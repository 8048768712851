import _ from 'lodash';

import config from '../config/main.json';
import ExtraButton from '../components/Home/ExtraButton';
import useDocumentTitle from '../hooks/useDocumentTitle';

const Main = () => {

    useDocumentTitle("myWaifu.Top");
    
    return (
        <>
            <div className="row w-100 my-5 justify-content-center">
                {config.bots.map(bot => 
                    <div className="col-lg-3 mx-3">
                        <div style={{
                            backgroundColor: "var(--secondary)",
                            marginTop: "3rem",
                            padding: "2rem",
                            textAlign: "center",
                            borderRadius: "3rem",
                        }} className='shadow-lg'>
                            <img src={"/images/" + bot.name + "/pfp.png"} alt={bot.name} className="rounded-circle w-50 mx-auto mb-4" />
                            <h3>{_.startCase(bot.name)}</h3>
                            <p>{bot.short}</p>
                            <ExtraButton link={bot.links.invite} variant="warning" icon="fa-solid fa-plus">Invite me!</ExtraButton>
                            <div className='my-3' />
                            <ExtraButton link={"/" + bot.name} variant="warning" icon="fa-solid fa-globe">Website</ExtraButton>
                            <div className='my-3' />
                        </div>
                    </div>
                )}
            </div>
        </>
    );

}

export default Main;