import { useEffect, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import useDocumentTitle from '../hooks/useDocumentTitle';
import useFetch from '../hooks/useFetch';

import Masonry from '../components/Gallery/Masonry';
import Loading from '../components/Loading';
import Search from '../components/Search/Search';
import axios from 'axios';
import Section from '../components/Elements/Section';
import Details from '../components/Library/Details';

const Gallery = (props) => {
  const { id } = useParams();
  
  const [waifuData, setWaifuData] = useState();
  const [loaded, setLoaded] = useState(false);
  const [images, setImages] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingCount, setLoadingCount] = useState(0);
  const [loadedCount, setLoadedCount] = useState(0);

  useDocumentTitle(!waifuData ? "Gallery" : waifuData.firstName + (waifuData.lastName ? " " + waifuData.lastName : ""));
  
  let page = parseInt(searchParams.get("page")) || 0;
  if (isNaN(page)) page = 0;

  const url = process.env.REACT_APP_SERVER_URL + "/gallery?bot=" + props.bot + "&" + window.location.search.substr(1) + (id !== undefined ? `id=${id}` : "");
  
  const { data, isPending, error, fetchUrl } = useFetch(url);

  useEffect(() => {
    fetchUrl(url);
  }, [searchParams]);

  useEffect(() => {
    setLoaded(false);
  }, [data]);
  
  const loadFunc = () => {
    searchParams.set("page", page + 1);
    setSearchParams(searchParams);
  }
  
  useState(() => {
    if (id !== undefined) {
      axios.get(process.env.REACT_APP_SERVER_URL + "/item?bot=" + props.bot + "&what=series&id=" + id).then(response => {
        if (response.status === 200) {
          setWaifuData(response.data);
        } else {
          return <Navigate to="../error/3542"/>
        }
      })
    }
  });
  

  //failed to fetch the data
  if (error) return <Navigate to="../error/3542"/>

  //set images
  if (!isPending && !loaded) {
    setLoaded(true);
    if (data.type) setImages(images.concat(data.files));
    else setImages(data.files);
  }

  const loading = (message) => {
  }

  return (
    !isPending ? 
    <div className='position-relative'>
      {/* {loadedCount !== loadingCount ? <Loading /> : null} */}
        <Section>
          {waifuData ? 
            <Details bot={props.bot} data={waifuData} type="waifu"/>
          : null}
        <Search bot={props.bot} title={waifuData ? "Filter cards" : null} />
        </Section>
        <InfiniteScroll dataLength={images.length} next={loadFunc} hasMore={data.hasMore} loader={<Loading />} endMessage={<p className='text-center my-3'>End of results.</p>}>
            <Masonry function={loading} images = {images}/> 
        </InfiniteScroll>
      </div>
    : <Loading />
  );
};
  
export default Gallery;