const BlehBlah = (props) => {
    return (
        <div className="bleh-blah" style={{
            width: props.width + "%",
            opacity: props.opacity,
            height: props.height
        }}></div>
    )
}

export default BlehBlah;