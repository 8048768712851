import { Button, Spinner } from "react-bootstrap";
import ReactImageAppear from 'react-image-appear';
import useFetch from "../../../hooks/useFetch";
import _ from 'lodash';
import { useState } from "react";

const Message = (props) => {
    const [loading, setLoading] = useState(false);
    const url = process.env.REACT_APP_SERVER_URL + '/embed?bot=' + props.bot;
    const { data, isPending, error, fetchUrl } = useFetch(url);

    const reroll = () => {
        setLoading(true);
        fetchUrl(url);

        setTimeout(() => {
            setLoading(false);
        }, 500);
    }

    return (
        <div className="py-3 discord-message row">
            <div className="col-sm-1 bot-pfp">
                <img src={"/images/" + props.bot + "/pfp.png"} alt="bot" className="discord-bot-pfp" />
            </div>
            <div className="col-sm-10">
                <div className="d-flex">
                    <p className="bot-name">{_.startCase(props.bot)}</p>
                    <div className="bot-mark" >
                        <i className="fa-solid fa-check" />
                        <p>BOT</p>
                    </div>
                </div>
                <div className="discord-embed">
                    {isPending || loading ? 
                        <div className="embed-spinner">
                            <div className="spinner-embed">
                                <Spinner />
                            </div>
                        </div>
                    : 
                        <>
                            <strong>Woah! A new waifu!</strong><br />
                            Congrats, Player!<br />
                            <br />
                            <strong>{data.name}</strong> from <strong>{data.franchise}</strong> has joined your harem!<br />
                            <br />
                            This is the <strong>first such waifu</strong> in your harem!<br />
                            Use <strong>/{props.bot[0]}harem</strong> to check all of your characters!<br />
                            <br />
                            <strong>Rarity:</strong><br />
                            <i className="red fa-solid fa-star" />
                            <i className="red fa-solid fa-star" />
                            <i className="red fa-solid fa-star" />
                            <i className="red fa-solid fa-star" />
                            <i className="red fa-solid fa-star" />
                            <i className="red fa-solid fa-star" /> 
                            <br />
                            <ReactImageAppear
                                src={data.img} 
                                className="mt-3 waifu-embed" 
                                alt="waifu"
                                animation="blurIn"
                                placeholderStyle={{
                                    backgroundColor: "#2f3136"
                                }}
                            />
                            <br />
                            <span className="small">{data.author ? "@" + data.author : null}</span><br />
                        </>
                    }
                </div>
                <Button variant="danger" className="embed-button" disabled={true}>Sell</Button>
                <Button variant="success" className="embed-button mx-2" onClick={reroll} disabled={loading || isPending}  >Roll again</Button>
            </div>
        </div>
    );
}

export default Message;