import BlehBlah from "./BlehBlah"

function random() {
    return Math.floor(Math.random() * (80 - 20 + 1) + 20)
}

const Channel = () => {
    
    return (
        <div className="channel">
            <i className="fa-solid fa-hashtag" />
            <BlehBlah width={random()} opacity="0.2" height="10px" />
        </div>
    )
}

export default Channel;