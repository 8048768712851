import { Col, Row } from "react-bootstrap";

const SmallStat = (props) => {
    return (
        <div>
            <div className="small-question">
                {props.question}
            </div>
            <div className="small-answer">
                {props.answer} 
            </div>
        </div>
    );
}

export default SmallStat;