import useDocumentTitle from '../hooks/useDocumentTitle';
import Button from '../components/Elements/Button';
import { useLocation } from 'react-router-dom';

import errors from '../config/errors';

const Error = (props) => {
  const { pathname }= useLocation();
  let code = 404
  if (props.code) code = parseInt(props.code);
  else if (pathname.split("/")[3]) code = pathname.split("/")[3];
  if (!errors[code]) code = 404; 

  useDocumentTitle("Error " + code);

  return (
    <div style={{paddingTop: "8rem", textAlign: "center"}}>
      <h1>Error {code}</h1>
      <p>{errors[code]}</p>
      {code == '086' ? <Button icon="fa-solid fa-right-to-bracket" path={process.env.REACT_APP_SERVER_URL + "/authorize/" + pathname.split("/")[1]}>Login</Button> : null}
      <Button path="/" icon="fa-solid fa-house">Home</Button>
      <img className="error-image" src="/images/nazuna/404.png" alt="error"/>
    </div>
  );
};
  
  export default Error;