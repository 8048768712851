import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import { AiFillHeart } from 'react-icons/ai';

const Image = (props) => {
  const [isHovered, setHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const style = {
    transition: "0.2s",
    transform: isHovered ? "scale(1.01)" : "scale(1)",
    position: "relative",
  }
  
  const coverStyle = {
    pointerEvents: "none",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    transition: "0.2s",
    opacity: isHovered ? "0.9" : "0",
    backgroundImage: "linear-gradient(to bottom, var(--image-cover-transparent), var(--image-cover-not-transparent))"
  }

  const loaderStyle = {
    minHeight: "400px",
    backgroundColor: "var(--secondary)",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "0.5s",
    transform: isHovered ? "scale(1.01)" : "scale(1)",
    pointerEvents: "none"
  }

  function loaded() {
    setIsLoading(false);
  }
  
  return (
    <div 
      style={style}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      
      <Link to={`../card/${props.id}`}>
        <LazyLoadImage 
            style={{width: "100%"}}
            src={props.src} 
            alt={props.characters[0].name}
            afterLoad={loaded}
        />
      </Link>

      {isLoading ? 
        <div style={loaderStyle}>
          <Spinner className="image-loader my-spinner"/>
        </div> : null}

      <div style={coverStyle}>
        <div className="image-details">
          {props.characters[0].id ?
            <Link to={`../waifu/${props.characters[0].id}`}>
              <div className="character-name">{props.characters[0].firstName}{props.characters[0].lastName ? " " + props.characters[0].lastName : ""}</div>
            </Link> : <div className="character-name">{props.characters[0]}</div>          
          }
          {props.franchises[0] ? 
            <Link to={`../series/${props.franchises[0].id}`}>
              <div className="franchise-name">{props.franchises[0].name}</div>
            </Link> : null
          }
          {props.favourite ? <AiFillHeart /> : null}
          {props.author ? <div className="author-name">@{props.author}</div> : null}
        </div>
      </div>
    </div>
  );
};
  
export default Image;