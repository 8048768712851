import { Col, Row } from "react-bootstrap";
import Panel from "../components/Elements/Panel";
import Section from "../components/Elements/Section";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { AiOutlineGif, AiOutlineWoman } from 'react-icons/ai';
import { GiCardQueenHearts } from 'react-icons/gi';
import { BsFillQuestionCircleFill, BsFillQuestionDiamondFill } from 'react-icons/bs';

const Contribute = () => {
    useDocumentTitle("Contribute");
    return (
        <Section>
            <Row>
                <Col lg="4">
                    <Panel link="card" icon=<GiCardQueenHearts />>
                        Add a new card
                    </Panel>
                </Col>
                <Col lg="4">
                    <Panel link="waifu" icon=<AiOutlineWoman />>
                        Add a new character
                    </Panel>
                </Col>
                <Col lg="4">
                    <Panel link="series" icon="fa-solid fa-film">
                        Add a new series
                    </Panel>
                </Col>
                <Col lg="4">
                    <Panel disabled={true} link="interactions" icon=<AiOutlineGif />>
                        Upload an interaction gif
                    </Panel>
                </Col>
                <Col lg="4">
                    <Panel link="uncategorized/card" icon=<BsFillQuestionCircleFill />>
                        Edit uncategorized cards
                    </Panel>
                </Col>
                <Col lg="4">
                    <Panel link="uncategorized/waifu" icon=<BsFillQuestionDiamondFill />>
                        Edit uncategorized characters
                    </Panel>
                </Col>
            </Row>
        </Section>
    );
}

export default Contribute;