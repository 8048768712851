import { useState } from 'react';
import { Form, Row } from "react-bootstrap";
import { Navigate, useSearchParams } from 'react-router-dom';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import useFetch from '../../hooks/useFetch';
import Title from "../Elements/Title";
import Tags from './Tags';

const Search = (props) => {
    const [selected, setSelected] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    
    const url = process.env.REACT_APP_SERVER_URL + "/find?bot=" + props.bot + "&what=";
    const { data, isPending, error, fetchUrl } = useFetch(url + "waifu");

    if (error) return <Navigate to="../error/3542"/>

    function handleOnSearchWaifu(string) {    
        fetchUrl(url + "waifu" + "&query=" + string);
    }

    function handleOnSearchSeries(string) {    
        fetchUrl(url + "series" + "&query=" + string);
    }

    function handleOnSelect(item) {
        const array = searchParams.get(item.type);

        function select() {
            if (searchParams.get(item.type)) searchParams.set(item.type, searchParams.get(item.type) + "," + item.id);
            else searchParams.set(item.type, item.id);
    
            searchParams.set("page", 0);
            setSearchParams(searchParams);
        }

        if (array) {
            let itemsArray = array.split(',');
            if (itemsArray.includes(item.id.toString())) {
                //already selected, remove
                itemsArray = itemsArray.filter(i => i !== item.id.toString());
                searchParams.set(item.type, itemsArray.join(','))
                setSearchParams(searchParams);
            } else {
                //not selected, select
                select();
            }
        } else {
            select();
        }

    }



    function formatResult(item) {
        let alreadySelected = false;
        if (selected.find(s => s.id === item.id && s.type === item.type)) alreadySelected = true;

        return (
            <>
                <img className="mini-search-img" src={item.image} alt={item.name}/> {alreadySelected ? <strong>{item.name}</strong> : item.name}
            </>
        )
    }

    const styling = {
        backgroundColor: "var(--secondary)",
        border: "1px solid black",
        color: "var(--text-color-light)",
        boxShadow: "none",
        zIndex: 5
    }

    const stylingSecond = {
        backgroundColor: "var(--secondary)",
        border: "1px solid black",
        color: "var(--text-color-light)",
        boxShadow: "none",
        zIndex: 4
    }

    const resetFilters = () => {
        setSearchParams(new URLSearchParams());
    }

    return (
        <div className="search">
            <Title>
                {props.title || "Filter gallery"} 
                {searchParams.get("waifu") || searchParams.get("series") || searchParams.get("tags") ?
                <div onClick={resetFilters} className='flag'>
                    <i className="fa-solid fa-xmark" />
                    <span className='mx-2'>Reset filters</span>
                </div>
                : null}
            </Title>
            <Form className="row">
                <Form.Group className="mt-4 col-lg-6 col-md-6">
                    <ReactSearchAutocomplete resultStringKeyName="empty" items={data} formatResult={formatResult} onSelect={handleOnSelect} onSearch={handleOnSearchWaifu} inputDebounce={500} styling={styling} placeholder="Included characters"/>
                </Form.Group>
                <Form.Group className="mt-4 col-lg-6 col-md-6">
                  <ReactSearchAutocomplete resultStringKeyName="empty" items={data} formatResult={formatResult} onSelect={handleOnSelect} onSearch={handleOnSearchSeries} inputDebounce={500} styling={stylingSecond} placeholder="Included series"/>
                </Form.Group>
            </Form>
            <Row className="mx-2">
                <Tags bot={props.bot} selected={selected} setSelected={setSelected}/>
            </Row>
        </div>
    );
}

export default Search;