import axios from 'axios';
import _ from 'lodash';
import { Route, Routes, useLocation } from "react-router-dom";
import routes from './tools/Routes';

import Sidebar from './components/Navbar/Sidebar';

import config from './config/main';
import './styles/App.css';
import { useEffect } from 'react';

function App() {

  let sidebar = false;
    
  //get section
  const { pathname } = useLocation();
  const path = pathname.split('/');
  const bot = path[1];
  const localization = path[2];
  
  //favicon
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    link.href = process.env.REACT_APP_SERVER_URL + '/icons/' + (bot ? bot : "undefined") + ".png";
  }, [bot]);

  const names = config.bots.map(b => b.name);

  if (names.includes(bot)) {
    //is a bot
    sidebar = true;
    if (sidebar) {
      if (document.documentElement.style.getPropertyValue('--sidebar-width') === "0px") document.documentElement.style.setProperty('--sidebar-width', "60px")
    }
    
    //add to title
    if (!document.title.includes(_.startCase(bot))) {
      document.title = document.title + " | " + _.startCase(bot);
    } 
    
    //set styles
    const botConfig = config.bots[names.indexOf(bot)];
    const styles = Object.keys(botConfig.colors);
    for (const style of styles) {
      document.documentElement.style.setProperty('--' + style, botConfig.colors[style]);
    }
  } else {
    document.title = "";
    const botConfig = config.main;
    const styles = Object.keys(botConfig.colors);
    for (const style of styles) {
      document.documentElement.style.setProperty('--' + style, botConfig.colors[style]);
    }
  }

  if (!localization) sidebar = false;
  
  return (
    <div>
      {sidebar ? <Sidebar auth={false} section={bot} now={path[2] || ""} /> : document.documentElement.style.setProperty('--sidebar-width', "0px")}
      <div className="main-container">
        <Routes>
          {routes.map((route, key) => {
            if (!route.children) {
              return <Route path={route.path} element={route.element} key={key}/>
            } else {
              return (
                <Route path={route.path} key={key}>
                  {route.children.map((child, childKey) =>
                      <Route path={child.path} section={route.path} element={child.element} key={childKey}/>
                  )}
                </Route>
              )
            }
          })}
        </Routes>
      </div>
    </div>
  );
}

export default App;
