import { Link } from "react-router-dom";

const Item = (props) => {
    const isNow = props.active.toLowerCase() === props.name.toLowerCase()|| (props.name === "Home" && !props.active);
    const className = (isNow) ? "nav-item active" : "nav-item";

    return (
        props.path ? 
            <Link to={props.path} className={className} id={"nav-" + props.name}>
                <div className="nav-icon position-relative">
                    <i className={props.icon}/>
                </div>
                <div className="nav-label">
                    {props.name}
                </div>
            </Link>
            :
            <a href={props.link} className={className} id={"nav-" + props.name}>
                <div className="nav-icon position-relative">
                    <i className={props.icon}/>
                </div>
                <div className="nav-label">
                    {props.name}
                </div>
            </a>
    );
}

export default Item;