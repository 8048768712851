import { useEffect, useState } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useDocumentTitle from "../hooks/useDocumentTitle";
import axios from 'axios';
import Loading from "../components/Loading";
import Masonry from "../components/Gallery/Masonry";

const Harem = (props) => {
    const { bot } = props;

    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState();
    const auth = useAuth();
    var { id } = useParams();
    
    if (!id && auth) id = auth.discordId;

    const url = process.env.REACT_APP_SERVER_URL + "/harem?bot=" + bot + "&id=" + id + "&page=" + (searchParams.get("page") || 0);
    useEffect(() => {
        if ((id || auth) && !data) {
            axios.get(url, {
                withCredentials: true
            }).then(response => {
                if (response.status === 200) setData(response.data);
            });
        }
    }, [auth, id]);

    console.log(data);
    useDocumentTitle(!data || data.error ? "Loading..." : data.username + "'s Harem");

    if (data?.error === "unauthorized") return <Navigate to="../error/086" />
    else if (data?.error === "private") return <Navigate to="../error/6942" />

    return (
        (!data || !data.waifus) ? <Loading /> : 
        <>
            <Masonry images={data.waifus.map(waifu => {
                return {
                    src: waifu.imageUrl,
                    characters: [waifu.name],
                    franchises: [],
                    favourite: waifu.favorite,
                    author: ""
                }
            })}/>
        </>
    );
}

export default Harem;