import BlehBlah from "./BlehBlah";
import User from "./User";

const Users = () => {
    return (
        <div className="col-sm-2 p-0">
            <div className="discord-header shadow-sm py-2 container-fluid"></div>
            <div className="px-3 mb-3">
                <BlehBlah width="60" height="8px" opacity="0.8" />
            </div>
            <User opacity="0.7" />
            <User opacity="0.4" />
            <User opacity="0.3" />
            <User opacity="0.5" />
            <div className="px-3 my-3">
                <BlehBlah width="60" height="8px" opacity="0.8" />
            </div>
            <User opacity="0.5" />
            <User opacity="0.6" />
            <User opacity="0.15" />
            <User opacity="0.3" />
            <User opacity="0.7" />
        </div>
    );
}

export default Users;