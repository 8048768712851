import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Choice from "./Choice";

const Tags = (props) => {
    const [selected, setSelected] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { data, isPending, error, fetchUrl } = useFetch(process.env.REACT_APP_SERVER_URL + "/get?bot=" + props.bot + "&type=waifu");

    const removeSelection = (id, type) => {
        const params = searchParams.get(type);
        if (params) {
            let array = params.split(',');
            array = array.filter(i => i !== id.toString());
            searchParams.set(type, array.join(','))
            setSearchParams(searchParams);
        }
    }

    useEffect(() => {
        setSelected([]);

        let url = process.env.REACT_APP_SERVER_URL + "/get?bot=" + props.bot + "&"
        //get waifus
        const waifus = searchParams.get("waifu");
        if (waifus) url += "waifu=" + waifus;
        //get series
        const series = searchParams.get("series");
        if (series) url += (url.includes('=') ? "&" : "") + "series=" + series;

        if (series || waifus) fetchUrl(url);
        else fetchUrl(process.env.REACT_APP_SERVER_URL + "/get?bot=" + props.bot + "&reset=true");
    }, [searchParams]);

    if (!isPending && !error && data.length && selected.length !== data.length) {
        setSelected(data);
    }

    if (!data.length && selected.length) {
        setSelected([]);
    }

    return (
        selected.map((tag, key) => <Choice key={key} function={() => removeSelection(tag.id, tag.type)} type={tag.type}>{tag.name}</Choice>)
    );
}

export default Tags;