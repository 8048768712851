import { Link } from "react-router-dom";

const Tag = (props) => {
    return (
        <Link className={"my-link" + (props.highlight ? " highlight" : "")} to={"../gallery/?tags=" + props.children}>
            {props.number > 0 ? " " : null}#{props.children}
        </Link>
    );
}

export default Tag;