import { useMediaQuery } from "react-responsive";

const Leaderboard = (props) => {

    const isMiddle = useMediaQuery({ maxWidth: 992 });
    
    return (
        <div className="my-5 leaderboard shadow col-lg-5 col-md-8 col-sm-10 lb-left" 
            style={{
                transform: !isMiddle ? `rotate(${props.rotate}deg)` : "none",
                marginRight: !isMiddle ? (props.pos === 'left' ? "-0.2rem" : "0") : "0",
                marginLeft: !isMiddle ? (props.pos === 'right' ? "-0.2rem" : "0") : "0"
            }}>
            <h4><i className={props.icon}></i> {props.title}</h4>
            <table className="mt-3">
                <tbody>
                    {!props.people ? null : 
                        props.people.map((person, key) => 
                            <tr key={key}>
                                <th scope="row">{props.people.indexOf(person) + 1}</th>
                                <td>{person.username}</td>
                                <td>{person.count}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Leaderboard;