import Section from "../components/Elements/Section";
import { Col, Row } from 'react-bootstrap';
import { useParams, Navigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useFetch from "../hooks/useFetch";
import BigImage from "../components/Card/BigImage";
import useAuth from "../hooks/useAuth";
import Loading from "../components/Loading";
import axios from "axios";

const submit = (data, id, bot) => {
    axios.post(process.env.REACT_APP_SERVER_URL + "/report?id=" + id + "&bot=" + bot, data, {
        withCredentials: true
    }).then(res => {
        if (res.status === 200) {
            if (res.data?.error) {
                if (res.data.error === 401) return alert("You need to log in first.");
                else return alert(res.data?.error);
            } 
            alert("Card has been reported successfully.")
            window.location.reload(false);
        }
    });
}

const Report = (props) => {
    const auth = useAuth();
    let { id } = useParams();
    
    if (id.includes('-')) id = id?.substring(0, id.indexOf('-'))
    
    const { bot } = props;
    const { register, handleSubmit } = useForm();
    const { data, isPending, error } = useFetch(process.env.REACT_APP_SERVER_URL + "/card?bot=" + props.bot + "&id=" + id);
    useDocumentTitle("Report #" + id);

    if (error) return <Navigate to="../error/3542"/>

    return (1 ? isPending ? <Loading /> :
        <Section>
            <Row className="big-panel shadow text-center">
                <Col lg="6" md="12">
                    <BigImage src={data.path} />
                </Col>
                <Col lg="6" md="12" className="my-3">
                    <h4 className="mb-4">Report card #<span className="colored">{id || props.data.id}</span></h4>
                    <form className="upload-form row" onSubmit={handleSubmit((data) => submit(data, id, bot))}>
                        <div className="form-group my-3 col-lg-12">
                            <textarea {...register("reason")} name="reason" placeholder="Reason - why should the card be removed?" required />
                            <input type="submit" id="submit-button" className="submit-button mx-auto" />
                        </div>
                    </form>
                </Col>
            </Row>
        </Section>
    : auth === null ? <Loading /> : <Navigate to="../error/086"/>);
}

export default Report;