import Container from 'react-bootstrap/Container';
import {Nav as BoostrapNav} from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';

const Nav = (props) => {
    return (
        <Navbar className='navbar navbar-expand-lg navbar-dark myNav shadow py-3' expand="lg">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <BoostrapNav className="mx-auto">
                        <BoostrapNav.Link className='mx-3' href="#home">Home</BoostrapNav.Link>
                        <BoostrapNav.Link className='mx-3' href="#features">Features</BoostrapNav.Link>
                        <BoostrapNav.Link className='mx-3' href={"/" + props.bot + "/gallery"}>Gallery</BoostrapNav.Link>
                        <BoostrapNav.Link className='mx-3' href="#leaderboards">Leaderboards</BoostrapNav.Link>
                        <BoostrapNav.Link className='mx-3' href="#team">Team</BoostrapNav.Link>
                    </BoostrapNav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Nav;