import BlehBlah from "./BlehBlah";
import Message from "./Message";

const Chat = (props) => {
    return (
        <div className="col-sm-8 chat p-0">
            <div className="discord-header shadow-sm py-2 container-fluid">
                <i className="fa-solid fa-hashtag mx-2 oversized" />
                <BlehBlah width="30" height="80%" opacity="0.6" />
            </div>
            <div className="chat-box px-5">
                    <Message bot={props.bot} />
                </div>
                <div className="message-box">
                    <div className="input-box" />
                </div>
            <div className="h-100">
            </div>
        </div>
    );
}

export default Chat;