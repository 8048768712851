import { Button } from "react-bootstrap";

const ExtraButton = (props) => {
    const style = {
        borderRadius: "2rem",
        fontSize: "1.1rem",
        textTransform: "uppercase",
        fontWeight: "700",
        width: "100%",
        paddingTop: "0.7rem",
        paddingBottom: "0.7rem",
        transform: props.rotate ? "rotate(-15deg)" : "" 
    }
    
    return (
        <Button href={props.link} target={!props.notBlank ? "_blank" : null} style={style} variant={props.variant}>
            {props.icon ? <i className={props.icon} style={{marginRight: "1rem"}} /> : null}
            {props.children}
        </Button>
    );
}

export default ExtraButton;