import { useMemo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Button from "../Elements/Button";
import Title from "../Elements/Title";
import Loading from "../Loading";
import _ from 'lodash';

import filters from "../../config/filters.json";
import Select from "./Select";
import { Row } from "react-bootstrap";

function getUrl(searchParams, type, id, bot) {
    let url = process.env.REACT_APP_SERVER_URL + "/library?bot=" + bot + "&what=" + type + (id !== undefined ? "&parent=" + id : "");
    for (const entry of searchParams.entries()) {
        url += `&${entry[0]}=${entry[1]}`;
    }
    return url;
}

const Filter = (props) => {
    let type = props.what.toLowerCase();
    if (type === 'characters') type = 'waifu';
    const [searchParams, setSearchParams] = useSearchParams();
    const { data, isPending, error, fetchUrl } = useFetch(getUrl(searchParams, type, props.id, props.bot));
    
    useEffect(() => {
        setTimeout(() => {
            const element = document.getElementById("search-input");
            if (element) element.value = searchParams.get("query");
        }, 500);
    }, []);

    useEffect(() => {
        fetchUrl(getUrl(searchParams, type, props.id, props.bot));
        props.startLoading();
    }, [searchParams]);
    
    useEffect(() => {
        props.function(data.files || []);
    }, [data]);

    const handleOnSearch = (e) => {
        let updatedSearchParams = new URLSearchParams(window.location.search);
        updatedSearchParams.set('page', '0');
        updatedSearchParams.set('query', e.target.value);

        setSearchParams(updatedSearchParams.toString());
    }

    const debouncedChangeHandler = useMemo(
        () => _.debounce(handleOnSearch, 300)
      , []
    );

    const handlePageSwitch = (value) => {
        let page = parseInt(searchParams.get("page"));
        page = isNaN(page) ? 0 : page;
        searchParams.set("page", page + value);
        setSearchParams(searchParams);
    }
    
    return (
        !isPending ? <>
            <Title>Filter {props.what.toLowerCase()}</Title>
            <div className="my-4 upload-form">
                <input type="text" id="search-input" onChange={debouncedChangeHandler} placeholder={"Search by " + (type === 'series' ? "title" : "name") + "..."}/>
            </div>
            <Row className="mb-4">
                {filters[type].map((filter, key) => <Select bot={props.bot} id={props.id} type={type} filter={filter} key={key} />)}
            </Row>
            <div className="page-control">
                <Button disabled={parseInt(searchParams.get("page")||0) <= 0 || !searchParams.get("page")} onClick={() => handlePageSwitch(-1)} space={false} icon="fa-solid fa-chevron-left" />
                <Button disabled={parseInt(searchParams.get("page")||0) >= Math.ceil(data.count/24) - 1} onClick={() => handlePageSwitch(1)} space={false} icon="fa-solid fa-chevron-right" />
                <span className="mx-2">Page {isNaN(parseInt(searchParams.get("page"))) ? 1 : (parseInt(searchParams.get("page")) + 1)}/{Math.ceil(data.count/24)}</span>
            </div>
        </> : <Loading />
    );
}

export default Filter;