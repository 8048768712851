import { useMediaQuery } from 'react-responsive'
import _ from 'lodash';

import Nav from "../components/Home/Nav";
import TitleScreen from "../components/Home/TitleScreen";
import useDocumentTitle from "../hooks/useDocumentTitle";

import main from '../config/main.json';
import Dark from "../components/Home/Dark";
import Discord from "../components/Home/Discord";
import useFetch from '../hooks/useFetch';
import Loading from '../components/Loading';
import ExtraButton from '../components/Home/ExtraButton';
import Leaderboard from '../components/Home/Leaderboard';
import Team from '../components/Home/Team';

const Home = (props) => {
  useDocumentTitle("Home");
  const { data, isPending, error } = useFetch(process.env.REACT_APP_SERVER_URL + "/stats?bot=" + props.bot);

  const isMiddle = useMediaQuery({ maxWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 575 });

  const botInfo = main.bots.find(e => e.name === props.bot);

  return (
    isPending || error ? <Loading /> :
    <>
      <Nav bot={props.bot} />
      <TitleScreen stats={data} links={botInfo.links} bot={props.bot} description={botInfo.description} header={botInfo.header} />
      <Dark>
        <h2 id="features" className="mt-5">Features</h2>
        <div className="col-lg-11 mx-auto row justify-content-center">
          <div 
            className="feature col-lg-3 col-md-8 col-sm-10" 
            style={{
              marginRight: !isMiddle ? "-5rem" : "0"
            }}
          >
            <h3>Collect cards</h3>
            Use the <strong>/{props.bot[0]}roll</strong> command to roll a random {props.bot === 'nazuna' ? "hentai " : null}anime cards! There are over <strong>{data.cards} cards</strong> to roll and you can get them in different rarities!
            {!isMobile ? <>You can test it out by using the <strong>green button</strong> on the right!</> : null }
          </div>
          <div className="col-lg-9 col-md-11 mb-5">
          {!isMobile ? <Discord bot={props.bot} /> : null} 
          </div>
        </div>
      </Dark>
      <section id="gallery" className='gallery-section'>
        <div className="row">
          <div className="col-lg-10 row mx-auto gallery">
            <h2 className="section-title my-5 text-center">Gallery</h2>
            <div className="col-lg-9 col-md-6 mx-auto gallery-first">
            <div className="mb-3 feature shadow gallery-tip">
                <p>{_.startCase(props.bot)} uses a player-contributed gallery with <strong>{data.cards}</strong> unique {props.bot === 'nazuna' ? "hentai " : null}images!<br />You can browse all the images through our website or upload some images of your favorite characters for rewards!</p>
              </div>
              <div className="gallery-action row">
                <div className="col-lg-5 mt-5 col-md-12">
                  <ExtraButton notBlank={true} link={"gallery"} variant="light" icon="fa-solid fa-image">Browse Gallery</ExtraButton>
                </div>
                <div className="col-lg-3 col-md-12">
                  <img src={"/images/" + props.bot + "/contribute.png"} className="gallery-img" alt="" />
                </div>
                <div className="col-lg-4 col-md-12">
                  <ExtraButton rotate={true} notBlank={true} link={"contribute"} variant="warning" icon="fa-solid fa-plus">Contribute</ExtraButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Dark>
        <div className='mb-5 col-lg-8 mx-auto'>
          <h2 id="leaderboards" className="my-5">Leaderboards</h2>
          <div className='mt-5 row justify-content-center'>
            <Leaderboard icon="fa-solid fa-image" title="Uploads" pos="left" rotate="-9" people={data.contributors} />
            <Leaderboard icon="fa-solid fa-pen" title="Edits" pos="right" rotate="9" people={data.editors} />
          </div>
        </div>
      </Dark>
      <footer className="dark shadow pb-4">
      </footer>
    </>
  );
}

export default Home;