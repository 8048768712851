import { Button as ReactButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SmallButton = (props) => {
    let style = {marginRight: "0.5rem"};

    if (props.space === false) style = {};

    if (props.path)
        return (
            <Link to={props.path}>
                <ReactButton style={{margin: 0, transform: "scale(0.9)"}} className={props.disabled ? "btn-disabled": ""} variant="primary" onClick={props.onClick}>{props.icon ? <i className={props.icon} style={style}/> : null}{props.children}</ReactButton>
            </Link>    
        );
    else
        return (
            <ReactButton style={{margin: 0, transform: "scale(0.9)"}} className={props.disabled ? "btn-disabled": ""} variant="primary" onClick={props.onClick}>{props.icon ? <i className={props.icon} style={style}/> : null}{props.children}</ReactButton>
        );
}

export default SmallButton;