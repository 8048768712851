import Image from './Image';
import MasonryRow from 'react-masonry-css';

const Masonry = (props) => {
    return (
        <MasonryRow breakpointCols={{default: 4, 1100: 3, 700: 2, 500: 1}} className="masonry-row">
            {props.images.map((image, key) =>
                <Image function={props.function} favourite={image.favourite} id={image.id} src={image.src} characters={image.characters} franchises={image.franchises} author={image.author} key={key}/>
            )}
        </MasonryRow>
    );
};
  
export default Masonry;