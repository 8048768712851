import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'

const Stat = (props) => {
    const isMobile = useMediaQuery({ maxWidth: 575 });

    return (
        <Row className="justify-content-center">
            <Col lg="10" sm="10" className="row stat justify-content-center">
                <Col sm="4" style={{textAlign: !isMobile ? "right" : "center", fontWeight: 600}}>
                    {props.question}
                </Col>
                <Col sm="1"/>
                <Col sm="4" style={{textAlign: !isMobile ? "left" : "center"}}>
                    {props.link ? 
                        <Link className={"my-link"} to={props.link}>
                            {props.answer}
                        </Link> 
                    : props.href ? 
                        <a className={"my-link"} href={props.href}>
                            {props.answer}
                        </a> 
                    :
                        <span className={props.highlight ? "highlight" : ""}>
                            {props.answer}
                        </span>
                    }
                </Col>
            </Col>
        </Row>
    );
}

export default Stat;