import { Spinner } from "react-bootstrap";

const Loader = () => {
    return (
        <div className="big-loader">
            <div className="w-100 h-100 position-relative">
                <Spinner className="position-absolute big-spinner" />
            </div>
        </div>
    )
}

export default Loader;