import { Button, Col, Row } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import Panel from "../components/Elements/Panel";
import Section from "../components/Elements/Section";
import Title from "../components/Elements/Title";
import useAuth from "../hooks/useAuth";
import useFetch from "../hooks/useFetch";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { IoAlert } from 'react-icons/io5';
import { MdExposureZero } from 'react-icons/md';

import admin from '../config/admin.json';
import { useEffect, useState } from "react";
import Loading from "../components/Loading";

const Admin = (props) => {
    const auth = useAuth();
    const [myPermissions, setMyPermissions] = useState([]);
    const { data, isPending, error } = useFetch(process.env.REACT_APP_SERVER_URL + "/admin/counts?bot=" + props.bot);
    useDocumentTitle("Reviewer Panel");

    useEffect(() => {
        if (auth) setMyPermissions(auth.bots[props.bot].permissions.map(p => admin.find(a => a.permission === p)));
    }, [auth]);


    if (auth !== null) {
        if (auth === false || !auth.bots[props.bot].permissions.length) {
            return <Navigate to="../error/086" />
        }
    }

    return (
        isPending || error ? <Loading /> :
        <Section>
            <div className="panel-second">
                <h1 className="colored bold mb-3">Reviewer Panel</h1>
                What do you want to do today?
            </div>
            <Row>
                {myPermissions.map((action, key) => 
                    <Col key={key} lg="4">
                        <Panel link={action.permission}>
                        <div className="bold colored">
                            {data[action.count.split(".")[0]][action.count.split(".")[1]]}
                        </div>
                        {action.title}
                        </Panel>
                    </Col>
                )}
            </Row>
        </Section>
    )
}

export default Admin;