import BlehBlah from "./BlehBlah";

function random() {
    return Math.floor(Math.random() * (80 - 20 + 1) + 20)
}

const User = (props) => {
    return (
        <div className="px-3 row mt-2">
            <div className="col-sm-4">
                <div className="discord-pfp" 
                    style={{
                        opacity: props.opacity
                    }} 
                />
            </div>
            <div className="col-sm-8 mt-2">
                <BlehBlah width={random()} height="8px" opacity="0.3" />
                <div className="mt-1">
                    <BlehBlah width={random()} height="5px" opacity="0.2" />
                </div>
            </div>
        </div>
    )
}

export default User;