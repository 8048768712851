import _ from 'lodash';
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import useFetch from "../hooks/useFetch";
import Section from "../components/Elements/Section";
import forms from '../config/forms';
import useDocumentTitle from "../hooks/useDocumentTitle";
import { Col, Row, ToastHeader } from 'react-bootstrap';
import Title from '../components/Elements/Title';
import { createRef, useEffect, useRef, useState, useSyncExternalStore } from 'react';
import Choice from '../components/Search/Choice';
import { WithContext as ReactTags } from 'react-tag-input';
import useAuth from '../hooks/useAuth';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import ReCAPTCHA from "react-google-recaptcha";
import toHex from 'colornames';
import Button from "../components/Elements/Button";
import { default as SelectComponent } from 'react-select';

import colorNames from '../config/colors.json';
import { closest } from 'color-2-name';
import axios from 'axios';

const colorData = Object.keys(colorNames).map(c => {
    return {
        label: c,
        value: c
    }
})

const recaptchaRef = createRef();

const Upload = (props) => {
    const auth = useAuth();
    const [oldData, setOldData] = useState({});
    const [captcha, setCaptcha] = useState(null);
    const [colors, setColors] = useState(["", ""]);
    const [range, setRange] = useState(-1);
    const [file, setFile] = useState("https://via.placeholder.com/720x40/"+ document.documentElement.style.getPropertyValue('--fifthary').substr(1) +"?text=");
    const [tags, setTags] = useState([]);
    const [genres, setGenres] = useState([]);
    const [discard, setDiscard] = useState(false);
    const [imgDetails, setImgDetails] = useState("");

    const [suggestions, setSuggestions] = useState([], []);

    //get section
    const { pathname } = useLocation();
    const path = pathname.split('/');
    const bot = path[1];
    const action = path[2];

    const [areRules, setAreRules] = useState(false);
    const [selected, setSelected] = useState([]);
    let { type, id } = useParams();
    if (action === 'reviewer') {
        type = props.type;
        id = props.id;
    }
    
    const { register, handleSubmit, setValue } = useForm();
    const fields = forms[type];
    
    const isEditing = (action === 'edit' || action === 'reviewer') && id !== undefined;
    
    useEffect(() => {
        if (!isEditing) {
            setRange("Extra Small");
            setColors(["white", "white"])
        } else axios.get(process.env.REACT_APP_SERVER_URL + "/info?bot=" + props.bot + "&what=" + type + "&id=" + id).then(response => {
            if (response.status === 200) {
                setOldData(response.data);
                const elements = response.data.characters || response.data.franchises || [];
                
                setTags(type === 'card' ? response.data.tags.map(t => {
                    return {
                        id: t,
                        text: t
                    }
                }) : type === 'series' ? response.data.categories.map(t => {
                    return {
                        id: t,
                        text: t
                    }
                }) : []);
                setGenres(type !== 'series' ? [] : response.data.genres.map(g => { 
                    return {
                        id: g,
                        text: g
                    }
                }));

                setFile(type === 'card' ? response.data.path : response.data.cover);
                const img = new Image();
                img.src = type === 'card' ? response.data.path : response.data.cover;
                img.onload = () => { 
                    setImgDetails(`${img.width}x${img.height}`);
                }

                if (type === 'waifu')  { 
                    setRange(response.data.chestSize);
                    setColors([response.data.hairColor !== 'unclassified' ? response.data.hairColor.toLowerCase() : "white", response.data.eyesColor !== 'unclassified' ? response.data.eyesColor.toLowerCase() : "white"]);
                }
                axios.get(process.env.REACT_APP_SERVER_URL + "/get?bot=" + bot + "&" + (type === 'card' ? "waifu" : "series") + "=" + elements.join(",")).then(response => {
                    if (response.status === 200) {
                        setSelected(response.data);
                    } else {
                        return <Navigate to="../error/3542" />
                    }
                });
            } else {
                return <Navigate to="../error/3542" />
            }
        });
    }, []);

    useEffect(() => {
        if (auth)
            setAreRules(auth.bots[props.bot].stats.uploads.approved < 1)
    }, [auth])

    useEffect(() => {
        if (type === 'card' || type === 'series') {
            axios.get(process.env.REACT_APP_SERVER_URL + "/tags?bot=" + bot + "&type=" + type).then(res => {
                if (res.status === 200) {
                    setSuggestions(res.data);
                }
            });
        }

    }, [])

    useDocumentTitle(_.startCase(type) + " - " + (!isEditing ? "Contribute" : action === 'reviewer' ? "Review" : "Edit"))

    const url = process.env.REACT_APP_SERVER_URL + "/find?bot=" + props.bot + "&what=";
    const { data, isPending, error, fetchUrl } = useFetch(url + (type === 'card' ? "waifu" : "series"));

    if (auth === false) {
        return <Navigate to="../error/086" />
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file.name.endsWith(".gif") || file.name.endsWith(".png") || file.name.endsWith(".jpg") || file.name.endsWith(".jpeg")) {
            let imgUrl = URL.createObjectURL(e.target.files[0]);
            let img = new Image()
            img.src = imgUrl;
            
            img.onload = () => {
                if (type !== 'card' || img.width > 800 || img.height > 800) {
                    setFile(imgUrl);
                } else {
                    alert("At least one dimension must be larger than 800 px.");
                    e.target.value = null;
                }
            };
        }
        else {
            alert("Only PNG/JPEG/JPG/GIF files are allowed.");
            e.target.value = null;
        }
    }

    const submit = async (data) => {;
        if (tags.length && genres.length) {
            data["tags"] = tags.map(t => t.text).join(',') + ';' + genres.map(g => g.text).join(',');
        } else if (tags.length) {
            data["tags"] = tags.map(t => t.text).join(',');
        } else {
            data["tags"] = [];
        }

        data["selected"] = selected.map(i => i.id);
        
        //captcha
        data["captcha"] = captcha;

        if (type === 'waifu') {
            data["hairColor"] = colors[0] || "white";
            data["eyesColor"] = colors[1] || "white";
            data["chestSize"] = range;
        }
        if (range) data["range"] = range;
        if (selected.length || fields[1].type !== 'autoselect') {
            if ((type === 'series' && tags.length && genres.length) || (type === 'card' && tags.length) || (type !== 'series' && type !== 'card')) {
                if (captcha || action === 'reviewer') {
                    //disable button
                    const buttons = document.getElementsByClassName("submit-button");
                    for (const button of buttons) {
                        button.disabled = true;
                        button.value = "Loading...";
                    }
                    
                    const formData = new FormData();
                    
                    const fileInput = document.getElementById("fileInput");
                    if (fileInput && fileInput.files && fileInput.files[0]) {
                        formData.append("file", fileInput.files[0]);
                    }
                    
                    if (document.getElementById("hidden-image")) data.imageUrl = document.getElementById("hidden-image").value;

                    for (const key of Object.keys(data)) {
                        if (key !== "file") {
                            formData.append(key, data[key]);
                        }
                    }
                    
                    formData.append('isEdit', isEditing);
    
                    if (action === 'reviewer') {
                        formData.append('what', 'submission');
                        formData.append('type', type);
                        formData.append('id', id);
                        formData.append('contributor', oldData.discordId);
                    } else {
                        // if(!(isEditing && type === 'card')) formData.append('file', data.file[0]);
                    }

                    let axiosConfig = {
                        withCredentials: true
                    };

                    axiosConfig["headers"] = {
                        'Content-Type': 'multipart/form-data'
                    }
                    
                    axios.post(process.env.REACT_APP_SERVER_URL + "/" + (isEditing ? (action === 'reviewer' ? "admin/review" : "edit") : "post") + "?bot=" + bot + "&what=" + type + (isEditing ? "&id=" + id : ""), formData, axiosConfig).then((response) => {
                        if (response) {
                            if (response.data && response.data.result && response.data.message) {
                                const {result, message} = response.data;
                                if (result === 'error') {
                                    alert(message);
                                    recaptchaRef.current.reset();
                                    for (const button of buttons) {
                                        button.disabled = false;
                                        button.value = null;
                                    }
                                } else {
                                    //success
                                    if (action !== 'reviewer') alert("Success! The submission is awaiting a review.");
                                    window.location.reload(false);
                                }
                            } else {
                                return;
                            }
                        }
                    });
                } else {
                    alert("You must complete the captcha challenge.");
                }
            } else {
                alert("You have to fill in *EVERY* field of the form.");
            }
        } else {
            alert("You have to select the tags and series/waifus.");
        }
    }

    const handleSearch = (string) => {
        fetchUrl(url + (type === 'card' ? "waifu" : "series") + "&query=" + string);
    }

    const handleSelect = (item) => {
      if (selected.find(i => i.id === item.id)) {
        setSelected(selected.filter(i => i.id !== item.id));
      } else {
        setSelected(selected.concat([item]));
      }
    }
    
    const removeItem = (item) => {
        setSelected(selected.filter(i => i.id !== item.id));
    }

    const handleAutoFill = () => {
        const firstName = document.getElementById("firstName");
        const lastName = document.getElementById("lastName");
        
        let query = firstName?.value;
        if (lastName && lastName.value) query += " " + lastName.value;
        
        let searchUrl = 'https://waifu.mywaifu.top/rest/character/' + query.toLowerCase().replace(' ', '+');

        axios.get(searchUrl).then(response => {
            if (response.status === 200) {
                setColors([response.data.data.hairColor.toLowerCase(), response.data.data.eyesColor.toLowerCase()])
            }
        });

        //get image
        axios.get("https://kitsu.io/api/edge/characters?filter[name]=" + query.toLowerCase().replace(' ', '+')).then(response => {
            if (response.status === 200) {
                const {data} = response;
                if (data && data.data?.length) {
                    const character = data.data[0];
                    const img = character.attributes?.image?.original;
                    
                    document.getElementById("fileInput").required = false;
                    document.getElementById("preview").src = img;
                    document.getElementById("hidden-image").value = img;
                }
            }
        })
    }

    function formatResult(item) {

        return (
            <>
                <img className="mini-search-img" src={item.image} alt={item.name}/> {item.name}
            </>
        )
    }

    const styling = {
        backgroundColor: "var(--fifthary)",
        border: "1px solid black",
        color: "var(--text-color-light)",
        boxShadow: "none",
        zIndex: 5
    }

    const handleTagAddition = (tag, field) => {
        if (field === 0) setTags([...tags, tag]);
        else setGenres([...genres, tag]);
    }

    const handleTagDelete = (i, field) => {
        if (field === 0) setTags(tags.filter((tag, index) => index !== i));
        else setGenres(genres.filter((genre, index) => index !== i));
    }

    const handleOnChange = (id, value) => {
        const array = [];
        for (let i = 0; i < colors.length; i++) {
            if (i === id) array.push(value);
            else array.push(colors[i]); 
        }

        setColors(array);
    }

    return (
        <Section>
            <Row  className="big-panel shadow justify-content-center">
                {isEditing ? action === 'reviewer' ? <Title>Review {type}</Title> : <Title>Edit {type}</Title> : <Title>Add a new {type}</Title>}
                <p>Please, follow the <span className='colored cursor-pointer' onClick={() => setAreRules(!areRules)}>rules.</span><br />Getting your submission rejected 20 times will permanently ban you from uploading.</p>
                {areRules ? <p dangerouslySetInnerHTML={ {__html: forms.rules[bot][type]} }></p> : null}
                <form className="upload-form col-lg-12 row" onSubmit={handleSubmit((data) => submit(data))}>
                    {fields.map((field, key) => {
                        switch (field.type) {
                            case "text":
                                return (
                                        <div className={"form-group my-3 col-lg-" + field.col} key={key}>
                                            {field.required ? <input {...register(field.name)} id={field.name} name={field.name} placeholder={field.placeholder} defaultValue={isEditing ? oldData[field.name] : null} required={!isEditing}/> : <input {...register(field.name)} defaultValue={isEditing ? oldData[field.name] : null} id={field.name} name={field.name} placeholder={field.placeholder}/>} 
                                            <div className="form-text">{field.tip}</div>
                                        </div>
                                    );
                            case "file":
                                return (
                                    <div className='col-lg-12 row my-3' key={key}>
                                        {action === 'reviewer' || isEditing && type === 'card' ? null :
                                            <div className="form-group col-lg-12">
                                                <input id="fileInput" className="form-control" onChange={handleFileChange} name="file" type="file" required={!isEditing}/>
                                                <div className="form-text">{field.tip}</div>
                                            </div>
                                        }
                                        {action === 'reviewer' ? <p>{file}</p> : null}
                                        <div className='text-center mt-3 col-lg-12'>
                                            <img id="preview" className='preview' alt="preview" src={file} />
                                            <p>{imgDetails}</p>
                                        </div>
                                    </div>
                                );
                            case "autoselect":
                                return (
                                    <div className="form-group my-3" key={key}>
                                        <ReactSearchAutocomplete placeholder={field.placeholder} styling={styling} resultStringKeyName="empty" key={key} items={data} formatResult={formatResult} onSelect={handleSelect} onSearch={handleSearch} />
                                        <div className="form-text">{field.tip}</div>
                                        <div className="selected-items">
                                            {selected.map((item, key) => <Choice function={() => removeItem(item)} type={type === 'waifu' ? 'series' : 'waifu'} key={key}>{item.name}</Choice> )}
                                        </div>
                                    </div>
                                );
                            case "tags": 
                                return (
                                    <div className={"tags-input form-group my-3 col-lg-" + field.col} key={key}>
                                        <ReactTags 
                                            placeholder={field.placeholder}
                                            tags={field.id === 0 ? tags : genres}
                                            suggestions={suggestions[field.id]}
                                            handleAddition={(tag) => handleTagAddition(tag, field.id)}
                                            handleDelete={(tag) => handleTagDelete(tag, field.id)}
                                            inputFieldPosition="bottom"
                                            allowDragDrop={false}
                                            allowUnique={true}
                                        />
                                        <div className="form-text">{field.tip}</div>
                                    </div>
                                );
                            case "range":
                                return (
                                    <div className={"form-group my-3 mb-5 col-lg-" + field.col} key={key}>
                                        <label className="form-label">{field.placeholder}</label>
                                        {range !== -1 ? <Slider onChange={(value) => setRange(field.marks[value])} min={0} marks={field.marks} step={null} defaultValue={Object.keys(field.marks)[Object.values(field.marks).findIndex(i => i === range)]}/> : null}
                                        <div className="form-text">{field.tip}</div>
                                    </div>
                                );
                            case "color":
                                return (
                                    <div className={"form-group my-3 col-lg-" + field.col} key={key}>
                                        <SelectComponent
                                            onChange={(obj) => handleOnChange(field.id, obj.value)}
                                            value={colorData.find(c => c.label === colors[field.id])}
                                            options={colorData}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: "var(--fifthary)",
                                                    borderColor: "black",
                                                    borderRadius: "2rem",
                                                    outline: "none",
                                                    boxShadow: "none",
                                                    paddingLeft: "0.5rem",
                                                    ':hover': {
                                                        borderColor: "var(--fourthary)",
                                                        boxShadow: "none"
                                                    }
                                                }),
                                                menu: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: "var(--fifthary)"
                                                }),
                                                option: (baseStyles, {data}) => ({
                                                    ...baseStyles,
                                                    backgroundColor: "var(--fifthary)",
                                                    transition: "0.2s",
                                                    ':hover': {
                                                        backgroundColor: data.value,
                                                        cursor: "pointer"
                                                    }
                                                }),
                                                singleValue: (baseStyles, {data}) => ({
                                                    ...baseStyles,
                                                    color: data.value,
                                                    borderRadius: "0.7rem"
                                                }),
                                                multiValueLabel: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--light)"
                                                }),
                                                multiValueRemove: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    ':hover': {
                                                        backgroundColor: "var(--text-color-light)"
                                                    }
                                                }),
                                                input: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--light)"
                                                })
                                            }}
                                        />
                                        <div className="form-text">{field.tip}</div>
                                    </div>
                                );
                            case "textarea":
                                return (
                                    <div className={"form-group my-3 col-lg-" + field.col} key={key}>
                                        {field.required ? <textarea defaultValue={isEditing ? oldData[field.name] : null} {...register(field.name)} name={field.name} placeholder={field.placeholder} required/> : <textarea {...register(field.name)} defaultValue={isEditing ? oldData[field.name] : null} name={field.name} placeholder={field.placeholder}/>} 
                                        <div className="form-text">{field.tip}</div>
                                    </div>
                                );
                            case "autofill":
                                return (
                                    <div key={key}>
                                        <Button onClick={handleAutoFill} icon="fa-solid fa-magnifying-glass">Find and auto-fill</Button>
                                        <div className='form-text mx-3'>{field.tip}</div>
                                        <input {...register("imageUrl")} type="hidden" name="imageUrl" id="hidden-image" />
                                        <hr />
                                    </div>
                                );
                            default:
                                break;
                        }
                    })}
                    {action !== 'reviewer' ? <ReCAPTCHA ref={recaptchaRef} className='my-3' onChange={(token) => setCaptcha(token)} sitekey="6Ld7unAkAAAAAIvK0xxvrD9-ExuLMeZ9xf0qq5DS" /> : null}
                    {action === 'reviewer' ? 
                        <>
                            <h5 className='mb-3 bold'>
                                <i className='fa-solid fa-user-pen mx-2' /> Review result
                            </h5>
                            <div onChange={() => setDiscard(false)}  className="form-check mx-5">
                                <input  className='form-check-input' {...register("verdict", { required: true })} type="radio" value="Accept" /> 
                                <label className="form-check-label">Accept</label>
                            </div>
                            <div onChange={() => setDiscard(true)} className="form-check mx-5">
                                <input className='form-check-input' {...register("verdict", { required: true })} type="radio" value="Reject" /> 
                                <label className="form-check-label">Reject</label>
                            </div> 
                            {discard ? 
                                <div className={"form-group my-3 col-lg-12"}>
                                    <textarea {...register("reason")} name="reason" placeholder="Reject reason" required/> 
                                </div>
                             : null}
                        </>
                    :  <div className="form-check" style={{"marginBottom": "2rem", "marginTop": "2rem"}}>
                            <input className="form-check-input" style={{"height": "1.2rem", "width": "1.2rem", "margin": "0.1rem", "marginRight": "2rem", "backgroundColor": "rgb(131 131 131)"}} type="checkbox" value="" id="flexCheckDefault" required />
                            <label className="form-check-label" for="flexCheckDefault">
                                I confirm that I have obtained the necessary consent from the author for the distribution of the files and content I am submitting. *
                            </label>
                        </div>
                    }
                    <input id="submit-button" type="submit" className="submit-button mx-auto" />
                </form>
            </Row>
        </Section>
    )
}

export default Upload;