import { Col, Row } from 'react-bootstrap';
import { Navigate } from "react-router-dom";
import Panel from "../components/Elements/Panel";
import Section from "../components/Elements/Section";
import Loading from "../components/Loading";
import Avatar from "../components/User/Avatar";
import SmallStat from "../components/User/SmallStat";
import useAuth from "../hooks/useAuth";
import useDocumentTitle from "../hooks/useDocumentTitle";

const Profile = (props) => {
    useDocumentTitle("Profile");
    const auth = useAuth();

    function convert(id) {
        const timeStamp = parseInt(id.toString().substr(0,8), 16)*1000;
        return new Date(timeStamp);
    }

    return (
        auth ?
            <Section>
                <Row>
                    <Col lg="3" md="4">
                        <div className="panel-second">
                            <Avatar id={auth.discordId} avatar={auth.avatar} />
                            <h4 className="text-center mt-3">{auth.username}</h4>
                            <p className="colored">{auth.bots[props.bot].role}</p>
                            <div>
                                <SmallStat question="Join Date" answer={convert(auth._id).toLocaleDateString()} />
                                {auth.bots[props.bot].stats.uploads ? 
                                    <SmallStat question="Submissions" answer={
                                        <>
                                            <span>{auth.bots[props.bot].stats.uploads.uploaded}</span>
                                            <span> ・ </span>
                                            <span className='green'>{auth.bots[props.bot].stats.uploads.approved}</span>
                                            <span> ・ </span>
                                            <span className='red'>{auth.bots[props.bot].stats.uploads.declined}</span>
                                        </>
                                    } /> : null
                                }
                                {auth.bots[props.bot].stats.edits ? 
                                    <SmallStat question="Edits" answer={
                                        <>
                                            <span>{auth.bots[props.bot].stats.edits.edited}</span>
                                            <span> ・ </span>
                                            <span className='green'>{auth.bots[props.bot].stats.edits.approved}</span>
                                            <span> ・ </span>
                                            <span className='red'>{auth.bots[props.bot].stats.edits.declined}</span>
                                        </>
                                    } /> : null
                                }
                                {auth.bots[props.bot].stats.edits && auth.bots[props.bot].permissions.length ? 
                                    <SmallStat question="Reviews" answer={
                                        <>
                                            <span>{auth.bots[props.bot].stats.reviews.reviewed}</span>
                                            <span> ・ </span>
                                            <span className='green'>{auth.bots[props.bot].stats.reviews.approved}</span>
                                            <span> ・ </span>
                                            <span className='red'>{auth.bots[props.bot].stats.reviews.declined}</span>
                                        </>
                                    } /> : null
                                }
                            </div>
                        </div>
                    </Col>
                    <Col lg="9" md="8">
                        <Row>
                            <Col lg="4">
                                <Panel link="../contribute" icon="fa-solid fa-upload">Upload</Panel>
                            </Col>
                            <Col lg="4">
                                <Panel link={"../gallery?user=" + auth.discordId} icon="fa-solid fa-image">Show my uploads</Panel>
                            </Col>
                            <Col lg="4">
                                <Panel disabled={true} link="../harem" icon="fa-solid fa-heart">Show my harem</Panel>
                            </Col>
                            { 
                                auth.bots[props.bot].permissions.length ? 
                                <Col lg="4">
                                    <Panel link="../reviewer" icon="fa-solid fa-user-pen">Reviewer Panel</Panel>
                                </Col>
                                : null
                            }
                        </Row>
                    </Col>
                </Row>
            </Section>
        : auth === null ? <Loading /> : <Navigate to="../error/086" />
    )
}

export default Profile;