import { Link } from "react-router-dom";

const Panel = (props) => {

    return (
        <Link to={props.disabled ? "" : props.link} className="no-link">
            <div className={"panel shadow" + (props.disabled ? " panel-disabled" : "")}>
                {props.icon ? 
                (typeof props.icon === 'string' ?
                <i className={props.icon + " panel-icon"} /> : props.icon) : null}
                <div>{props.children}</div>
            </div>
        </Link>
    );
}

export default Panel;