import Spinner from 'react-bootstrap/Spinner';

const Loading = () => {
    return (
        <div className="loading">
            <Spinner style={{position: "absolute", margin: "auto", left: 0, top: 0, right: 0, bottom: 0}} animation="border" variant="light" />
        </div>
    )
}

export default Loading;