import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Button from "../components/Elements/Button";
import Section from "../components/Elements/Section";
import Details from "../components/Library/Details";
import Loading from "../components/Loading";
import useAuth from "../hooks/useAuth";
import useFetch from "../hooks/useFetch";
import Card from "./Card";

import { FiRefreshCcw } from 'react-icons/fi';

const Uncategorized = (props) => {
    const [state, setState] = useState(0);
    const { type } = useParams();
    const url = process.env.REACT_APP_SERVER_URL + "/uncategorized?bot=" + props.bot + "&type=" + type;
    const { data, isPending, error, fetchUrl } = useFetch(url);
    const auth = useAuth();
    

    if (error) return <Navigate to="../error/3542" />
    if (auth === false) return <Navigate to="../error/086" />
    
    const reroll = () => {
        fetchUrl(url);
    }

    return (
        isPending ? <Loading /> :
        <Section>
            <div onClick={reroll} className="mx-0 panel">
                <FiRefreshCcw />
                <h5>Reroll</h5>
            </div>
            { type === 'card' ?
                <Card bot={props.bot} data={data} />
            : 
                <Details bot={props.bot} type={type} data={data} />
            }
        </Section>
    );
}

export default Uncategorized;