import Channels from "./DIscord/Channels";
import Chat from "./DIscord/Chat";
import Users from "./DIscord/Users";

const Discord = (props) => {
    return (
        <div className="discord row shadow">
            <Channels />
            <Chat bot={props.bot} />
            <Users />
        </div>
    )
}

export default Discord;