import { Link } from "react-router-dom";

const Frail = (props) => {
    return (
        <Link to={props.link} className="no-link">
            <div className={"frail" + (props.highlight ? " highlight" : "")}>
                {props.children}
            </div>
        </Link>
    )
}

export default Frail;