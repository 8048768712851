import ExtraButton from "./ExtraButton";

const TitleScreen = (props) => {
    const { bot, stats } = props;

    return (
        <div className="row title-div">
            <div className="col-sm-12 col-md-8 col-lg-6 mx-auto">
                <img src={"/images/" + bot + "/title.png"} alt={bot} className="title-img mx-auto mt-5" />
                <div dangerouslySetInnerHTML={ {__html: props.header} } />
                <p className="mt-4">
                    {props.description}
                </p>
                <div className="row mx-auto">
                    <div className="col-lg-4 col-md-12 mt-4">
                        <ExtraButton link={props.links.patreon} variant="light" icon="fa-brands fa-patreon">Patreon</ExtraButton>    
                    </div>
                    <div className="col-lg-4 col-md-12 mt-4">
                        <ExtraButton link={props.links.invite} variant="warning" icon="fa-solid fa-plus">Invite me!</ExtraButton>
                    </div>
                    <div className="col-lg-4 col-md-12 mt-4">
                        <ExtraButton link={props.links.discord} variant="light" icon="fa-brands fa-discord">Discord</ExtraButton>    
                    </div>
                </div>
                <div className="row mx-auto counters">
                  <div className="col-lg-4 col-md-12 mt-4">
                    <i className="fa-solid fa-user"></i>
                    <p>{stats.players} players</p>
                  </div>
                  <div className="col-lg-4 col-md-12 mt-4">
                    <i className="fa-solid fa-image"></i>
                    <p>{stats.cards} unique cards</p>
                  </div>
                  <div className="col-lg-4 col-md-12 mt-4">
                    <i className="fa-solid fa-globe"></i>
                    <p>{stats.servers} servers</p>
                  </div>
                </div>
          </div>
        </div>  
    )
}

export default TitleScreen;