import { Col, Row } from "react-bootstrap";
import _ from 'lodash';
import filters from '../../config/filters.json';
import Frail from "./Frail";
import SmallButton from '../Elements/SmallButton';
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import { useEffect, useState } from "react";
import Loading from "../Loading";

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
}

const Details = (props) => {
    const myFilters = filters[props.type];
    const [data, setData] = useState();
    const [franchises, setFranchises] = useState([]);
    const auth = useAuth();

    const {original} = props;
    
    useEffect(() => {
        if (props.data) setData(props.data);
    }, [])

    useEffect(() => {
        if (props.data !== data) {
            setData(props.data);
        }
    }, [props.data]);

    useEffect(() => {
        getFranchises();
    }, [data]);


    const getFranchises = () => {
        if (props.type === 'waifu' && data) {
            const series = original ? (data.franchises ? data.franchises.join(',') : original.franchises.join(',')) : data.franchises.join(',');
            axios.get(process.env.REACT_APP_SERVER_URL +"/get?bot=" + props.bot + "&series=" + series).then(response => {
                if (response.status === 200) {
                    setFranchises(response.data);
                }
            })
        }
    }

    return (
        !data ? <Loading /> :
        <div className="panel-second mx-0 mb-5">
            <Row>
                {props.reviewType ? <h5 className="colored bold">{props.reviewType}</h5> : null}
                <Col lg="3" md="5">
                    <img className="big-cover shadow" src={data.cover || original.cover} alt="cover img" />
                </Col>
                <Col lg="9" md="7" className="item-details">
                    <h5 className={"colored" + (original ? (props.type === 'series' ? (data.name && data.name !== original.name ? " highlight" : "") : ((data.firstName && data.firstName !== original.firstName) || (data.lastName && data.lastName !== original.lastName) ? " highlight" : "") ) : "")}>{props.type === 'series' ? (data.name || original.name) : (data.firstName || original.firstName) + ((data.lastName || (original ? original.lastName : false)) ? " " + (data.lastName || original.lastName) : "")}</h5>
                    {props.type === 'series' ? <p className={original && data.description ? data.description !== original.description ? " highlight" : "" : ""}>{data.description || (original ? original.description : "")}</p> : null}
                    {props.type === 'series' ?
                        myFilters.map((filter, key) =>
                            <div key={key}>
                                <p className={"bold" + (original ? !arraysEqual(data[filter], original[filter]) ? " highlight" : "" : "")}>{_.startCase(filter)}</p>
                                <div className="d-flex w-100 details-tags">
                                    {data[filter] ? !Array.isArray(data[filter]) ?
                                        <Frail link={"../" + props.type  + "?" + filter + "=" + data[filter]} key={key}>{_.startCase(data[filter])}</Frail>
                                    : data[filter].map((flair, key) =>
                                        <Frail link={"../" + props.type  + "?" + filter + "=" + flair} key={key}>{flair}</Frail>
                                    ) : null}
                                </div>
                            </div>
                        ) :
                        <>
                        <p className={"bold mt-4" + (original ? original.age !== data.age ? " highlight" : "" : "")}>Age</p>
                        <p>{data?.age || original?.age}</p>
                        <p className={"bold mt-4" + (original ? !arraysEqual(original.franchises, data.franchises) ? " highlight" : "" : "")}>Related series</p>
                        <div className="d-flex details-tags">
                            {franchises ? franchises.map((franchise, key) =>  <Frail link={"../series/" + franchise.id} key={key}>{franchise.name}</Frail>) : null}
                        </div>
                        <p className="bold">Tags</p>
                        <div className="d-flex details-tags">
                            {myFilters.map((filter, key) => 
                                original ?
                                    <Frail link="" key={key} highlight={data[filter] ? data[filter] !== original[filter] : false}>{_.startCase(data[filter] ? data[filter] : original[filter]) + " " + _.startCase(filter)}</Frail> 
                                    : 
                                    <Frail link={"../" + props.type  + "?" + filter + "=" + data[filter]} key={key}>{_.startCase(data[filter]) + " " + _.startCase(filter)}</Frail>
                            )}
                        </div>
                        </>
                    }
                    {}
                    
                    {auth && !props.reviewType ? 
                        <div className="mt-2">
                            <SmallButton path={"../edit/" + props.type + "/" + data.id} icon="fa-solid fa-pen">Edit</SmallButton>
                        </div>
                    : null}
                </Col>
            </Row>
        </div>
    );
}

export default Details;