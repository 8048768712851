import Error from '../pages/Error';
import Home from '../pages/Home';
import User from '../pages/User';
import Gallery from '../pages/Gallery';
import Card from '../pages/Card';

import Profile from '../pages/Profile';

import config from '../config/main';
import Contribute from '../pages/Contribute';
import Upload from '../pages/Upload';
import Report from '../pages/Report';
import Library from '../pages/Library';
import Admin from '../pages/Admin';
import Review from '../pages/Review';
import Uncategorized from '../pages/Uncategorized';
import Redirect from '../pages/Redirect';
import Main from '../pages/Main';
import Harem from '../pages/Harem';

const routes = [
    //redirect to api
    { path: '/', element: <Main /> }
]

//get bot-separate routes
for (const bot of config.bots) {
    routes.push({ 
        path: `/${bot.name}` , 
        children: [
            { path: '', element: <Home bot={bot.name} /> },
            { path: 'profile', element: <Profile bot={bot.name} /> },
            { path: 'gallery', element:  <Gallery bot={bot.name} key={Math.random} /> },
            { path: 'card/:id', element:  <Card bot={bot.name} /> },
            { path: 'contribute', element:  <Contribute bot={bot.name} /> },
            { path: 'contribute/uncategorized/:type', element: <Uncategorized bot={bot.name} />},
            { path: 'contribute/:type', element:  <Upload bot={bot.name} /> },
            { path: 'series', element: <Library bot={bot.name} key={Math.random} type="Series"/> },
            { path: 'series/:id', element: <Library bot={bot.name} key={Math.random*2} type="Characters"/> },
            { path: 'waifu', element: <Library bot={bot.name} key={Math.random*3} type="Characters"/> },
            { path: 'waifu/:id', element: <Gallery bot={bot.name} key={Math.random*5} /> },
            { path: 'characters/:id', element: <Gallery bot={bot.name} key={Math.random*5} /> },
            { path: 'edit/:type/:id', element: <Upload bot={bot.name} /> },
            { path: 'user/:id', element: <Error code="2137" /> },
            { path: 'reviewer', element: <Admin bot={bot.name} /> },
            { path: 'reviewer/review/:what/:type', element: <Review bot={bot.name} />},
            { path: 'harem/:id?', element: <Harem bot={bot.name} /> },
            { path: 'report/:id', element: <Report bot ={bot.name} />}
        ]
    });
} 

//get errors
routes.push(
    { path: '/error', element: <Error />, title: "Error" },
    { path: '*', element: <Error />, title: "Error" }
);

export default routes;