import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Loader from '../components/Elements/Loader';
import Section from '../components/Elements/Section';
import Details from '../components/Library/Details';
import Item from '../components/Library/Item';
import Loading from '../components/Loading';
import Filter from '../components/Search/Filter';
import useDocumentTitle from '../hooks/useDocumentTitle';
import useFetch from '../hooks/useFetch';

const Library = (props) => {
    const [items, setItems] = useState([]);
    const { id } = useParams();

    const { data, isPending, error, fetchUrl } = useFetch(process.env.REACT_APP_SERVER_URL + "/item?bot=" + props.bot + "&what=" + props.type.toLowerCase() + "&id=" + id);
    
    useDocumentTitle(id === undefined || isPending || error ? props.type : props.type === 'Characters' ? data.name : data.firstName + (data.lastName ? " " + data.lastName : ""));

    const startLoading = () => {
        document.documentElement.style.setProperty('--loader', 1);
        setTimeout(() => {
            document.documentElement.style.setProperty('--loader', 0);
        }, 800);
    }

    return (
        <Section>
            {id !== undefined ? !isPending && !error ? 
                <Details bot={props.bot} data={data} type={props.type === 'Characters' ? 'series' : 'waifu'} />
                : <Loading />
            : null}
            <Filter startLoading={startLoading} bot={props.bot} id={id} what={props.type} function={setItems} />
            <Row className='position-relative'>
                <Loader />
                {items.map((item, key) => <Item link={id === undefined ? props.type.toLowerCase() : props.type === 'Characters' ? 'waifu' : 'series'} src={item.img} name={item.name} id={item.id} key={key} />)}
            </Row>
        </Section>
    );
}

export default Library;