import { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { default as SelectComponent } from 'react-select';
import makeAnimated from 'react-select/animated';
import useFetch from '../../hooks/useFetch';
import Loading from '../Loading';
import _ from 'lodash';

function getUrl(searchParams, type, filter, id, bot) {
    let url = process.env.REACT_APP_SERVER_URL + "/filters?bot=" + bot + "&what=" + type + "&distinct=" + filter + (id !== undefined ? "&parent=" + id : "");
    for (const entry of searchParams.entries()) {
        url += `&${entry[0]}=${entry[1]}`;
    }
    return url;
}

const Select = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { data, isPending, error, fetchUrl } = useFetch(getUrl(searchParams, props.type, props.filter, props.id, props.bot));
    const [defaults, setDefaults] = useState([]);

    useEffect(() => {
        const params = searchParams.get(props.filter);
        if (params) {
            setDefaults(params.split(',').map(i => {
                return {
                    label: _.startCase(i),
                    value: i
                }
            }));
        }
    }, []);

    useEffect(() => {
        fetchUrl(getUrl(searchParams, props.type, props.filter, props.id, props.bot));
    }, [searchParams]);

    const handleOnChange = (array) => {
        if (array.length) {
            searchParams.set(props.filter, array.map(i => i.value).join(','));
        } else {
            searchParams.delete(props.filter);
        }

        searchParams.set("page", "0");
        setSearchParams(searchParams);
    }

    return (
        !isPending && !error ?
            <Col lg="6" className='my-1'>
                <SelectComponent 
                    onChange={handleOnChange}
                    components={makeAnimated()}
                    isMulti={true}
                    options={data}
                    placeholder={"Select " + _.lowerCase(props.filter) + "..."}
                    defaultValue={defaults}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: "var(--fifthary)",
                            borderColor: "black",
                            borderRadius: "2rem",
                            outline: "none",
                            boxShadow: "none",
                            paddingLeft: "0.5rem",
                            ':hover': {
                                borderColor: "var(--fourthary)",
                                boxShadow: "none"
                            }
                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: "var(--fifthary)"
                        }),
                        option: (baseStyles, {data}) => ({
                            ...baseStyles,
                            backgroundColor: "var(--fifthary)",
                            transition: "0.2s",
                            ':hover': {
                                backgroundColor: !props.filter.includes("Color") ? "var(--fourthary)" : data.value,
                                cursor: "pointer"
                            }
                        }),
                        multiValue: (baseStyles, {data}) => ({
                            ...baseStyles,
                            backgroundColor: !props.filter.includes("Color") ? "var(--fourthary)" : data.value,
                            borderRadius: "0.7rem"
                        }),
                        multiValueLabel: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "var(--light)"
                        }),
                        multiValueRemove: (baseStyles, state) => ({
                            ...baseStyles,
                            ':hover': {
                                backgroundColor: "var(--text-color-light)"
                            }
                        }),
                        input: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "var(--light)"
                        })
                    }}
                />
            </Col>
        : <Loading />
    )
}

export default Select;