import { Navigate, useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";

import { Col, Row } from 'react-bootstrap';
import BigImage from "../components/Card/BigImage";
import Button from "../components/Elements/Button";
import Container from "../components/Elements/Container";
import Loading from "../components/Loading";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Character from "../components/Elements/Character";
import Stat from "../components/Card/Stat";
import Tag from "../components/Elements/Tag";
import Section from "../components/Elements/Section";
import Title from "../components/Elements/Title";
import { useEffect, useState } from "react";
import axios from "axios";

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
}

function convert(id) {
    const timeStamp = parseInt(id.toString().substr(0,8), 16)*1000;
    return new Date(timeStamp);
}

const Card = (props) => {
    let { id } = useParams();
    if (!id) id = props.id;
    const { original } = props;

    const [data, setData] = useState();
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (props.data && !data) {
            setData(props.data);
            setIsPending(false);
        } else {
            axios.get(process.env.REACT_APP_SERVER_URL + "/card?bot=" + props.bot + "&id=" + id).then(res => {
                if (res.status === 200) {
                    setData(res.data);
                    setIsPending(false);
                } else {
                    setError(true);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (data != props.data) {
            setData(props.data);
        }
    }, [props.data])

    useDocumentTitle(props.reviewType ? ("Card Edit - Review") : ("Card #" + id));
    
    if (error) return <Navigate to="../error/3542"/>
    return (
        isPending ? <Loading /> : 
        <Section>
            <Row className="big-panel shadow text-center">
                {props.reviewType ? <h5 className="mb-5 bold colored">{props.reviewType}</h5> : null}
                <Col lg="6" md="12">
                    <BigImage id={props.data ? "copy" : "source"} src={data.path} />
                </Col>
                <Col lg="6" md="12" className="my-3">
                    <h4 className="mb-4">Card #<span className="colored">{id || props.data.id}</span></h4>
                    {!props.reviewType ? <Stat question="Rolled:" answer={data.count || 0}/> : null}
                    {!props.reviewType ? <Stat question="Sold:" answer={data.sold || 0}/> : null}
                    {!original ? 
                        <Stat question="Author:" answer={data.author || "?"}/> 
                        : 
                        <Stat question="Author:" answer={data.author ? data.author : original.author} highlight={data.author !== undefined}/> 
                    }
                    {data.source || original?.source ? <Stat question="Source:" highlight={original?.source && data.source !== original.source} answer={(data.source || original?.source).substring(0, 24)} href={data.source || original?.source}/> : null}
                    {!props.reviewType ? <Stat question="Upload date:" answer={convert(data._id).toLocaleDateString()}/> : null}
                    {!props.reviewType && !props.data ? <Stat question="Uploaded by:" answer={data.user[0] ? data.user[0].username || data.user[0].discordId : data.discordId} link={"../user/" + data.discordId}/> : null}
                    {data.tags ? <Stat question="Tags:" answer={data.tags.map((tag, key) => <Tag number={key} key={key} highlight={original ? !arraysEqual(data.tags, original.tags) : false}>{tag}</Tag>)} /> : null}
                    {!props.reviewType ? 
                        <>
                            <Button icon="fa-solid fa-pen" path={"../edit/card/" + data.id}>Edit</Button>
                            <Button icon="fa-solid fa-circle-exclamation" path={"../report/" + data.id} variant="danger">Report</Button>
                            <Button icon="fa-solid fa-star" disabled="true">Wishlist</Button>
                        </>
                    : null}
                    <Row style={{marginTop: "1rem"}} className="justify-content-center">
                        <Col lg="6" md="12">
                            <p className={"label" + (original ? !arraysEqual(data.characters, original.characters) ? " highlight" : "" : "")}>Included characters</p>
                            <Row className="justify-content-center">
                                {data.includedCharacters.map((character, key) => 
                                    <Col lg="4" md="3" sm="6" key={key}>
                                        <Character id={character.id} name={character.firstName + (character.lastName ? " " + character.lastName : "")} image={character.cover} />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                        <Col lg="6">
                            <p className="label">Included series</p>
                            <Row className="justify-content-center">
                                {data.includedFranchises.map((franchise, key) => 
                                    <Col lg="4" md="3" sm="6" key={key + data.includedCharacters.length}>
                                        <Character type="series" id={franchise.id} name={franchise.name} image={franchise.cover} />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Section>
    );
}

export default Card;