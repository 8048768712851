import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth.js';
import Hamburger from '../Icons/Hamburger';
import Item from './Item.js';

const Sidebar = (props) => {
    const auth = useAuth();
    const [isToggled, setToggle] = useState(false);
    const section = props.section;

    const isMobile = useMediaQuery({ maxWidth: 575 });
    
    useEffect(() => {
        document.documentElement.style.setProperty("--sidebar-width", !isMobile ? "60px" : "0");
    }, [isMobile])
    
    function toggle () {
        document.documentElement.style.setProperty("--sidebar-width", !isMobile ? (isToggled ? "60px" : "200px") : (isToggled ? "0" : "60px"));
        setToggle(!isToggled);
    }

    return (
        <div className='position-relative'>
            {!isMobile ? null :
                <div className='mobile-sidebar shadow'>
                    <Hamburger toggle={toggle} />
                </div>
            
            }
            <div className="sidebar" style={{ overflow: 'hidden'}}>
                <div className="nav-title" style={{paddingTop: "0.8rem", marginBottom: "3rem", display: "flex"}}>
                    <div className="icons">
                        <Hamburger toggle={toggle}/>
                    </div>
                    <div className="labels" style={{marginLeft: "0.7rem", marginTop: "0.5rem"}}>
                        <div className='label'>{_.startCase(section)}</div>
                    </div>
                </div>
                <div className='nav-items'>
                    <Item icon="fa-solid fa-house" name="Home" path={`/${section}/`} active={props.now} />
                    <Item icon="fa-solid fa-image" name="Gallery" path={`/${section}/gallery/`} active={props.now} />
                    <div className='break' />
                    <Item icon="fa-solid fa-layer-group" name="Series" path={`/${section}/series/`} active={props.now} />
                    <Item icon="fa-solid fa-heart" name="Characters" path={`/${section}/waifu/`} active={props.now} />
                    <div className='break' />
                    {auth ? 
                        <>
                            <Item icon="fa-solid fa-user" name="Profile" path={`/${section}/profile/`} active={props.now} />
                            <Item icon="fa-solid fa-arrow-up-from-bracket" name="Contribute" path={`/${section}/contribute/`} active={props.now} />
                            {auth.bots[section].permissions.length ? <Item icon="fa-solid fa-user-pen" name="Reviewer" path={`/${section}/reviewer/`} active={props.now} /> : null}
                        </>
                        :
                        null
                    }
                    {!auth ? 
                        <Item icon="fa-solid fa-right-to-bracket" name="Sign In" link={process.env.REACT_APP_SERVER_URL + "/authorize/" + section} active={props.now} />
                        :
                        <Item icon="fa-solid fa-right-from-bracket" name="Sign Out" link={process.env.REACT_APP_SERVER_URL + "/logout?where=" + section} active={props.now} />
                    }
                </div>
                <Link to="/">
                    <div className='nav-copy'>
                        <img className="nav-logo" src="/images/logo.png" alt="myWaifu" />
                        <div className='nav-label'>myWaifu.Top</div> 
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default Sidebar;