import BlehBlah from "./BlehBlah";
import Channel from "./Channel";



const Channels = () => {
    return (
        <div className="col-sm-2 p-0">
            <div className="discord-header shadow-sm py-2 container-fluid">
                <BlehBlah width="80" opacity="0.6" height="80%" />
                <i className="mx-2 fa-solid fa-chevron-down" />
            </div>
            <div className="channels">
                <div className="category">
                    <i className="fa-solid fa-chevron-down" />
                    <BlehBlah width="40" opacity="0.5" height="10px" />
                </div>
                <Channel />
                <Channel />
                <Channel />
                <Channel />
                <Channel />
                <Channel />
                <div className="category mt-3">
                    <i className="fa-solid fa-chevron-down" />
                    <BlehBlah width="60" opacity="0.5" height="10px" />
                </div>
                <Channel />
                <Channel />
                <Channel />
                <Channel />
                <Channel />
                <div className="category mt-3">
                    <i className="fa-solid fa-chevron-down" />
                    <BlehBlah width="30" opacity="0.5" height="10px" />
                </div>
                <Channel />
                <Channel />
                <Channel />
            </div>
        </div>
    )
}

export default Channels;