import { Button as ReactButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Button = (props) => {
    let style = {marginRight: "0.5rem"};
    const variant = props.variant || "primary"
    if (props.space === false) style = {};

    if (props.path) {
        if (!props.path.includes("http"))
            return (
                <Link to={props.path}>
                    <ReactButton className={props.disabled ? "btn-disabled": ""} variant={variant} onClick={props.onClick}>{props.icon ? <i className={props.icon} style={style}/> : null}{props.children}</ReactButton>
                </Link>    
            );
        else
            return (
                <a href={props.path}>
                    <ReactButton className={props.disabled ? "btn-disabled": ""} variant={variant} onClick={props.onClick}>{props.icon ? <i className={props.icon} style={style}/> : null}{props.children}</ReactButton>
                </a>
            );
    } else
        return (
            <ReactButton className={props.disabled ? "btn-disabled": ""} variant={variant} onClick={props.onClick}>{props.icon ? <i className={props.icon} style={style}/> : null}{props.children}</ReactButton>
        );
}

export default Button;