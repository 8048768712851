import axios from "axios";
import _ from 'lodash';
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useParams } from "react-router-dom";
import Button from "../components/Elements/Button";
import Section from "../components/Elements/Section";
import Details from "../components/Library/Details";
import Loading from "../components/Loading";
import useAuth from "../hooks/useAuth";
import useFetch from "../hooks/useFetch";
import Card from "./Card";
import Upload from "./Upload";

const Review = (props) => {
    const auth = useAuth();
    const [discard, setDiscard] = useState(false);
    const { what, type } = useParams();
    const permission = `review/${what}/${type}`;
    const { data, isPending, error } = useFetch(process.env.REACT_APP_SERVER_URL + "/admin/review?bot=" + props.bot + "&what=" + what + "&type=" + type);

    const { register, handleSubmit } = useForm();

    //get section
    const { pathname } = useLocation();
    const path = pathname.split('/');
    const bot = path[1];

    //failed to fetch data
    if (error) return <Navigate to="../error/3542" />;
    //not authorized
    if (auth !== null) {
        if (auth === false || !auth.bots[props.bot].permissions.includes(permission)) return <Navigate to="../error/086" />;
    }

    if (what === 'edit' && type === 'card') {
        setTimeout(() => {
            const copy = document.getElementById("copy");
            if (copy) copy.src = document.getElementById("source").src;
        }, 1000);
    }
    
    //edits submit
    const submit = async (formData) => {
        document.getElementById("submit-button").disabled = true;
        document.getElementById("submit-button").value = "Loading...";

        axios.post(process.env.REACT_APP_SERVER_URL + "/admin/review-edit?id=" + data.file._id + "&type=" + type + "&bot=" + bot, formData, {
            withCredentials: true
        }).then(res => {
            if (res.status === 200) {
                window.location.reload(false);
            }
        });
    }

    return (
        isPending ? <Loading /> :
        <>
            <Section>
                <div className="row big-panel">
                    {data.response === 'empty' ? 
                        <div>
                            <p className="m-3">Nothing to review.</p> 
                            <Button icon="fa-solid fa-chevron-left" path="../reviewer">Go back</Button>
                        </div>
                    :
                        <div className="text-center">
                            <h5 className="colored bold">Review {type} {what}</h5> 
                            <div className="mt-4 w-100">
                                <div>
                                    <div className="mx-3 text-center">Submitted by <span className="bold">{data.contributor.username}</span></div>
                                    <div className="mx-3"><div className="bold colored">User ID:</div> {data.contributor.discordId}</div>
                                    <div className="mx-3"><div className="bold colored">{_.startCase(type)} ID:</div> {data.file.id || data.file.file.id}</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Section>
            {what === 'edit' && data.response !== 'empty' ? 
                <>
                    {type === 'card' ?
                        <>
                            <Card bot={props.bot} reviewType="Original card" id={data.file.file.id} />
                            <Card bot={props.bot} reviewType="Edited card" data={data.file.file} original={data.original} />
                        </>
                    : 
                        <Section>
                            <Details bot={props.bot} reviewType={"Original " + type} type={type} data={data.original} />
                            <Details bot={props.bot} reviewType={"Edited " + type} type={type} data={data.file.file} original={data.original} />
                        </Section>
                    }
                    
                    <Section>
                        <div className="big-panel shadow">
                            <form className="upload-form row" onSubmit={handleSubmit((data) => submit(data))} >
                                <h5 className='mb-3 bold'>
                                    <i className='fa-solid fa-user-pen mx-2' /> Review result
                                </h5>
                                <div onChange={() => setDiscard(false)}  className="form-check mx-5">
                                    <input  className='form-check-input' {...register("verdict", { required: true })} type="radio" value="Accept" /> 
                                    <label className="form-check-label">Accept</label>
                                </div>
                                <div onChange={() => setDiscard(true)} className="form-check mx-5">
                                    <input className='form-check-input' {...register("verdict", { required: true })} type="radio" value="Reject" /> 
                                    <label className="form-check-label">Reject</label>
                                </div> 
                                {discard ? 
                                    <div className={"form-group my-3 col-lg-12"}>
                                        <textarea {...register("reason")} name="reason" placeholder="Reject reason" required/> 
                                    </div>
                                : null}
                                <input type="submit" id="submit-button" className="submit-button mx-auto" />
                            </form>
                        </div>
                    </Section>
                </>
            : data.response !== 'empty' ? <Upload bot={props.bot} type={type} id={data.file.id} /> : null}

        </>
    );
}

export default Review;