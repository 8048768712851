import { Link } from "react-router-dom";

const Character = (props) => {
    return (
        <Link className="my-link" to={`../` + (props.type ? `series/${props.id}` : `waifu/${props.id}`)}>
            <img className="char-pfp shadow-lg" src={props.image} alt={props.name}/>
            <p style={{fontSize: "14px", textAlign: "center", marginTop: "0.5rem"}}>{props.name}</p>
        </Link>
    );
}

export default Character;